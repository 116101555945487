import S from "../../components/layout/layout.styled";
import game5 from "../../assets/images/BijousKnifes.png";
import game1 from "../../assets/images/ChaosGame.png";
import game4 from "../../assets/images/Cryptoblast3.png";
import game3 from "../../assets/images/NYCRicshow.png";
import game2 from "../../assets/images/TimberKekGame.png";
import YearnChamp from "../../assets/images/YearnChamp-640.png";
import YearnFam from "../../assets/images/YearnFam-640.png";
import YearnBuddy from "../../assets/images/Yearnbuddy-640.png";
import Avatar1 from "../../assets/images/avatars/avatar1.png";
import Avatar2 from "../../assets/images/avatars/avatar2.png";
import Avatar3 from "../../assets/images/avatars/avatar3.png";
import Avatar4 from "../../assets/images/avatars/avatar4.png";
import Avatar5 from "../../assets/images/avatars/avatar5.png";
import Avatar6 from "../../assets/images/avatars/avatar6.png";
import Avatar7 from "../../assets/images/avatars/avatar7.png";
import Avatar8 from "../../assets/images/avatars/avatar8.png";
import Avatar9 from "../../assets/images/avatars/avatar9.png";
import Avatar10 from "../../assets/images/avatars/avatar10.png";
import Avatar11 from "../../assets/images/avatars/avatar11.png";
import Avatar12 from "../../assets/images/avatars/avatar12.png";
import Avatar13 from "../../assets/images/avatars/avatar13.png";
import Avatar14 from "../../assets/images/avatars/avatar14.png";
import Avatar15 from "../../assets/images/avatars/avatar15.png";
import Avatar16 from "../../assets/images/avatars/avatar16.png";
import { Round, TourGuide } from "../types";
import { WinningHeaderContentType } from "../types/custom-modal";

export const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

export const EXPLORER_URL: {
  [key: string | number]: string;
} = {
  80001: `${process.env.REACT_APP_MUMBAI_POLYGON_EXPLORER}`,
  56: `${process.env.REACT_APP_BSC_EXPLORER}`,
};

export const BACKEND_URL: {
  [key: number]: string;
} = {
  56: `${process.env.REACT_APP_BSC_BACKEND_URL}`,
  80001: `${process.env.REACT_APP_BACKEND_URL}`,
};

//referral path
export const REFERRAL_PATH_NAME = "myreferral";
export const CRICKET_PATH_NAME = "cricket";
export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

//User type
export const FREE_USER = "FREE_USER";
export const PAID_USER = "PAID_USER";

//Button type.
export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const GRADIENT = "gradient";

//Draw counts
export const ONE_K_DRAW_PROGRESS_COUNT = 1000;
export const TEN_K_DRAW_PROGRESS_COUNT = 10000;
export const HUNDRED_K_DRAW_PROGRESS_COUNT = 100000;
export const ONE_K_DRAW = "ONE_K_DRAW";
export const TEN_K_DRAW = "TEN_K_DRAW";
export const HUNDRED_K_DRAW = "HUNDRED_K_DRAW";
export const ONEK_DRAW = "1K DRAW";
export const TENK_DRAW = "10K DRAW";
export const HUNDREDK_DRAW = "100K DRAW";
export const ONE_K = "1";
export const TEN_K = "2";
export const HUNDRED_K = "3";
export const ONE = "1";
export const TEN = "10";
export const HUNDRED = "100";
export const NONE = "NONE";
export const HUNDRED_K_SLOT = `1 - ${HUNDRED_K_DRAW_PROGRESS_COUNT}`;
export const CURRENT_DRAW = "CURRENT DRAW";

//Rounds
export const ROUND_1 = "Round 1";
export const ROUND_2 = "Round 2";
export const ROUND_3 = "Round 3";

//Gradients for progress bar.
export const GRADIENT1 = "gradient1";
export const GRADIENT2 = "gradient2";
export const GRADIENT3 = "gradient3";

export const GRAD_COLOR_1 = "radial-gradient(72.13% 132.54% at 27.87% 38.89%,#206cfc 0%,#26e7c6 100%)";
export const GRAD_COLOR_2 = "radial-gradient(72.13% 132.54% at 27.87% 38.89%,#24ff00 0%,#fee500 100% ) ";
export const GRAD_COLOR_3 = "radial-gradient(72.13% 132.54% at 27.87% 38.89%,#e9b314 0%,#ea02fd 100% ) ";
export const GRAD_COLOR_4 = "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)";
export const TextGradient = "linear-gradient(25deg, rgba(32,247,252,1) 4%, rgba(32,108,252,1) 34%, rgba(32,247,252,1) 94%)";
export const TransparentTextGradient =
  "linear-gradient(135deg, rgba(32,108,252,0) 0%, rgba(32,108,252,0) 40%, rgba(3,231,198,1) 50%, rgba(32,108,252,0) 60%, rgba(32,108,252,0) 100%)";
export const TransparentTextGradientForGradColor3 =
  "linear-gradient(135deg, rgba(32,108,252,0) 0%, rgba(32,108,252,0) 40%,  rgb(255, 195, 0)  50%, rgba(32,108,252,0) 60%, rgba(32,108,252,0) 100%)";

//Ellipse container border gradients
export const ELLIPSE_GRAD_COLOR_1 = "linear-gradient(black, black),radial-gradient(circle at top left,#206cfc 0%,#26e7c6 100%)";
export const ELLIPSE_GRAD_COLOR_2 = "linear-gradient(black, black),radial-gradient(circle at top left,#24ff00 0%,#fee500 100% ) ";
export const ELLIPSE_GRAD_COLOR_3 = "linear-gradient(black, black),radial-gradient(circle at top left,#e9b314 0%,#ea02fd 100% ) ";

//Levels used in my team component
export const LEVELS = [
  {
    label: "Level1",
    value: "1",
  },
  {
    label: "Level2",
    value: "2",
  },
  {
    label: "Level3",
    value: "3",
  },
  {
    label: "Level4",
    value: "4",
  },
  {
    label: "Level5",
    value: "5",
  },
];

//Random avatars
export const AvatarList = [
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
  Avatar14,
  Avatar15,
  Avatar16,
];

export const WinningHeaderContent: WinningHeaderContentType = {
  1: {
    text1: "FEW MINUTES AWAY...",
    text2: "FROM BEING A ",
    text3: "WINNER!",
  },
  2: {
    text1: "DON'T BLINK ",
    text2: "YOU MAY MISS IT.",
  },
  3: {
    text1: "RELAX, SIT BACK",
    text2: "SEE YOURSELF BEING A ",
    text3: "WINNER!",
  },
  4: {
    text1: "ADDITIONALLY, THE WINNER WILL RECEIVE AN NFT,",
    text2: "AN ACCESS PASS, AND LIFETIME BENEFITS.",
  },
};

//Amounts
export const paidUserReferralAmountByLevel: { [key: string]: number } = {
  "1": 7.5,
  "2": 4,
  "3": 2.5,
  "4": 2,
  "5": 1.5,
};

export const freeUserReferralAmountByLevel: { [key: string]: number } = {
  "1": 1,
  "2": 0.5,
  "3": 0,
  "4": 0,
  "5": 0,
};

// Ticket and draw amounts
export const REFERRAL_BASE_AMOUNT = 50;
export const TICKET_AMOUNT = 55;
export const maxSingleUserTickets = 100;
export const oneKDrawWinningAmount = 100;
export const tenKDrawWinningAmount = 250;
export const hundredKDrawWinningAmount = 1000;
export const topReferrerOneKAmount = 2000;
export const topReferrerHundredKAmount = 200000;
export const AMOUNT_PER_REFERRAL = 2;
export const MAX_ONE_K_DRAW_COUNT = Math.floor(HUNDRED_K_DRAW_PROGRESS_COUNT / ONE_K_DRAW_PROGRESS_COUNT);

// Credit percenteges
export const ticket5 = 2;
export const ticket25 = 3;
export const ticket50 = 5;
export const ticket80 = 10;

export const creditPercentage: { [key: string]: string } = {
  "tickets >=5": `${ticket5}%`,
  "tickets >=25": `${ticket25}%`,
  "tickets >=50": `${ticket50}%`,
  "80 tickets": `${ticket80}%`,
};

//Connectors
export const METAMASK = "METAMASK";
export const WALLET_CONNECT = "WALLET_CONNECT";

// export const freeUserReferralPercent = new Map([
//   ["1", "0.02"],
//   ["2", "0.01"],
//   ["3", "0"],
//   ["4", "0"],
//   ["5", "0"],
// ]);

// export const paidUserReferralPercent = new Map([
//   ["1", "0.15"],
//   ["2", "0.08"],
//   ["3", "0.05"],
//   ["4", "0.04"],
//   ["5", "0.03"],
// ]);

//Yearn token
export const YEARN_TOKEN = "YEARN";
export const YEARN_TOUR_MINUTES = 10;

export const PremiumPriceList = [
  "$1 is expected as a fee.",
  "The exact and Nearest (+5 or -5) match is rewarded.",
  `${YEARN_TOKEN} tokens are rewarded.`,
  "Wins a pool of upto $25000",
];

export const FreePriceList = [
  "No fee is expected.",
  "Only the exact match is rewarded.",
  `Only the ${YEARN_TOKEN} tokens are rewarded.`,
  "Not eligible for the Jackpot prediction.",
];

//YEARN AVATARS
export const YEARN_BUDDY = YearnBuddy;
export const YEARN_CHAMP = YearnChamp;
export const YEARN_FAM = YearnFam;

//Guide
export const participationGuide = [
  { id: "1", label: "Capturing the Winning Moments", link: `${process.env.REACT_APP_PARTICIPANT_CAPTURING_WINNING_MOMENTS_VIDEO_URL}` },
  { id: "2", label: "Lifetime Access to YearnFamily NFT", link: `${process.env.REACT_APP_PARTICIPANT_LIFETIME_ACCESS_TO_YEARN_NFT_VIDEO_URL}` },
  { id: "3", label: "Instant Earnings Withdrawal", link: `${process.env.REACT_APP_PARTICIPANT_INSTANT_EARNINGS_WITHDRAWAL_VIDEO_URL}` },
  { id: "4", label: "Claiming Your YEARN Token", link: `${process.env.REACT_APP_PARTICIPANT_CLAIMING_YEARN_TOKEN_VIDEO_URL}` },
];
// status: ["upcoming", "live", "completed", "expired", "later"]

const tournament = {
  name: "Cricket World Cup 2023",
  totalMatches: 48,
  matchType: "ODI",
};

export const salePeriod = {
  startsAt: "07 UTC, 01 JAN 2023",
  endsAt: "07 UTC, 15 JAN 2023",
};

export const cricketTeams = [
  {
    id: 1,
    tournament: tournament,
    matchNumber: 13,
    matchTimeStamp: 1697229000000,
    matchVenue: "Ahmedabad",
    // Pakistan vs India
    teamA: {
      name: "Pakistan",
      flag: "pk",
    },
    teamB: {
      name: "India",
      flag: "in",
    },
    status: "expired",
    pool: 0,
  },
  {
    id: 2,
    tournament: tournament,
    matchNumber: 14,
    matchTimeStamp: 1697401800000, //Oct 16 2023 02:00 PM
    matchVenue: "Lucknow",
    teamA: {
      name: "Australia",
      flag: "au",
    },
    teamB: {
      name: "Sri Lanka",
      flag: "lk",
    },
    status: "expired",
    pool: 1,
  },
  {
    id: 3,
    tournament: tournament,
    matchNumber: 15,
    matchTimeStamp: 1697488200000, //Oct 17 2023 02:00 PM
    matchVenue: "Dharamsala",
    //South Africa vs Netherlands
    teamA: {
      name: "Netherlands",
      flag: "nl",
    },
    teamB: {
      name: "South Africa",
      flag: "za",
    },
    status: "expired",
    pool: 2,
  },
  {
    id: 4,
    tournament: tournament,
    matchNumber: 16,
    matchTimeStamp: 1697574600000, //Oct 18 2023 02:00 PM
    matchVenue: "Chennai",
    //New Zealand vs Afghanistan,
    teamA: {
      name: "New Zealand",
      flag: "nz",
    },
    teamB: {
      name: "Afghanistan",
      flag: "af",
    },
    status: "expired",
    pool: 3,
  },
  {
    id: 5,
    tournament: tournament,
    matchNumber: 17,
    matchTimeStamp: 1697661000000, //Oct 19 2023 02:00 PM
    matchVenue: "Pune",
    //India vs Bangladesh
    teamA: {
      name: "India",
      flag: "in",
    },
    teamB: {
      name: "Bangladesh",
      flag: "bd",
    },
    status: "expired",
    pool: 4,
  },
  {
    id: 6,
    tournament: tournament,
    matchNumber: 18,
    matchTimeStamp: 1697747400000, //Oct 20 2023 02:00 PM
    matchVenue: "Bengaluru",
    // Australia vs Pakistan
    teamA: {
      name: "Australia",
      flag: "au",
    },
    teamB: {
      name: "Pakistan",
      flag: "pk",
    },
    status: "expired",
    pool: 5,
  },
  {
    id: 7,
    tournament: tournament,
    matchNumber: 19,
    matchTimeStamp: 1697864400000, // Oct 21 2023 10:30 AM
    //Netherlands vs Sri Lanka
    teamA: {
      name: "Netherlands",
      flag: "nl",
    },
    teamB: {
      name: "Sri Lanka",
      flag: "lk",
    },
    status: "expired",
    pool: 6,
  },
  {
    id: 8,
    tournament: tournament,
    matchNumber: 20,
    matchTimeStamp: 1697877000000, // Oct 21 2023 02:00 PM
    // England vs South Africa
    teamA: {
      name: "England",
      flag: "gb-eng",
    },
    teamB: {
      name: "South Africa",
      flag: "za",
    },
    status: "expired",
    pool: 7,
  },
  {
    id: 9,
    tournament: tournament,
    matchNumber: 21,
    matchTimeStamp: 1697963400000, // Oct 22 2023 02:00 PM
    //India vs New Zealand
    teamA: {
      name: "India",
      flag: "in",
    },
    teamB: {
      name: "New Zealand",
      flag: "nz",
    },
    status: "expired",
    pool: 8,
  },
  {
    id: 10,
    tournament: tournament,
    matchNumber: 22,
    matchTimeStamp: 1698049800000, // Oct 23 2023 02:00 PM
    // Pakistan vs Afghanistan
    teamA: {
      name: "Pakistan",
      flag: "pk",
    },
    teamB: {
      name: "Afghanistan",
      flag: "af",
    },
    status: "expired",
    pool: 9,
  },
  {
    id: 11,
    tournament: tournament,
    matchNumber: 23,
    matchTimeStamp: 1698136200000, // Oct 24 2023 02:00 PM
    matchVenue: "Mumbai",
    // South Africa vs Bangladesh
    teamA: {
      name: "South Africa",
      flag: "za",
    },
    teamB: {
      name: "Bangladesh",
      flag: "bd",
    },
    status: "expired",
    pool: 10,
  },
  {
    id: 12,
    tournament: tournament,
    matchNumber: 24,
    matchTimeStamp: 1698222600000, // Oct 25 2023 02:00 PM
    matchVenue: "Delhi",
    // Australia vs Netherlands
    teamA: {
      name: "Australia",
      flag: "au",
    },
    teamB: {
      name: "Netherlands",
      flag: "nl",
    },
    status: "expired",
    pool: 11,
  },
  {
    id: 13,
    tournament: tournament,
    matchNumber: 25,
    matchTimeStamp: 1698309000000, // Oct 26 2023 02:00 PM
    matchVenue: "Bengaluru",
    // England vs Sri Lanka
    teamA: {
      name: "England",
      flag: "gb-eng",
    },
    teamB: {
      name: "Sri Lanka",
      flag: "lk",
    },
    status: "expired",
    pool: 12,
  },
  {
    id: 14,
    tournament: tournament,
    matchNumber: 26,
    matchTimeStamp: 1698395400000, // Oct 27 2023 02:00 PM
    matchVenue: "Chennai",
    // Pakistan vs South Africa
    teamA: {
      name: "Pakistan",
      flag: "pk",
    },
    teamB: {
      name: "South Africa",
      flag: "za",
    },
    status: "expired",
    pool: 13,
  },
  {
    id: 15,
    tournament: tournament,
    matchNumber: 27,
    matchTimeStamp: 1698469200000, // Oct 28 2023 10:30 AM
    matchVenue: "Dharamsala",
    //Australia vs New Zealand
    teamA: {
      name: "Australia",
      flag: "au",
    },
    teamB: {
      name: "New Zealand",
      flag: "nz",
    },
    status: "expired",
    pool: 14,
  },
  {
    id: 16,
    tournament: tournament,
    matchNumber: 28,
    matchTimeStamp: 1698481800000, // Oct 28 2023 02:00 PM
    matchVenue: "Kolkata",
    // Netherlands vs Bangladesh
    teamA: {
      name: "Netherlands",
      flag: "nl",
    },
    teamB: {
      name: "Bangladesh",
      flag: "bd",
    },
    status: "expired",
    pool: 15,
  },
  {
    id: 17,
    tournament: tournament,
    matchNumber: 29,
    matchTimeStamp: 1698568200000, // Oct 29 2023 02:00 PM
    matchVenue: "Lucknow",
    // India vs England
    teamA: {
      name: "India",
      flag: "in",
    },
    teamB: {
      name: "England",
      flag: "gb-eng",
    },
    status: "expired",
    pool: 16,
  },
  {
    id: 18,
    tournament: tournament,
    matchNumber: 30,
    matchTimeStamp: 1698654600000, // Oct 30 2023 02:00 PM
    matchVenue: "Pune",
    //Afghanistan vs Sri Lanka
    teamA: {
      name: "Afghanistan",
      flag: "af",
    },
    teamB: {
      name: "Sri Lanka",
      flag: "lk",
    },
    status: "expired",
    pool: 17,
  },
  {
    id: 19,
    tournament: tournament,
    matchNumber: 31,
    matchTimeStamp: 1698741000000, // Oct 31 2023 02:00 PM
    matchVenue: "Kolkata",
    //Pakistan vs Bangladesh
    teamA: {
      name: "Pakistan",
      flag: "pk",
    },
    teamB: {
      name: "Bangladesh",
      flag: "bd",
    },
    status: "expired",
    pool: 18,
  },
  {
    id: 20,
    tournament: tournament,
    matchNumber: 32,
    matchTimeStamp: 1698827400000, // Nov 01 2023 02:00 PM
    matchVenue: "Pune",
    // New Zealand vs South Africa
    teamA: {
      name: "New Zealand",
      flag: "nz",
    },
    teamB: {
      name: "South Africa",
      flag: "za",
    },
    status: "expired",
    pool: 19,
  },
  {
    id: 21,
    tournament: tournament,
    matchNumber: 33,
    matchTimeStamp: 1698913800000, // Nov 02 2023 02:00 PM
    matchVenue: "Pune",
    // India vs Sri Lanka
    teamA: {
      name: "India",
      flag: "in",
    },
    teamB: {
      name: "Sri Lanka",
      flag: "lk",
    },
    status: "expired",
    pool: 20,
  },
  {
    id: 22,
    tournament: tournament,
    matchNumber: 34,
    matchTimeStamp: 1699000200000, // Nov 03 2023 02:00 PM
    matchVenue: "Lucknow",
    // Netherlands vs Afghanistan
    teamA: {
      name: "Netherlands",
      flag: "nl",
    },
    teamB: {
      name: "Afghanistan",
      flag: "af",
    },
    status: "expired",
    pool: 21,
  },
  {
    id: 23,
    tournament: tournament,
    matchNumber: 35,
    matchTimeStamp: 1699074000000, // Nov 04 2023 10:30 AM
    matchVenue: "Bengaluru",
    // New Zealand vs Pakistan
    teamA: {
      name: "New Zealand",
      flag: "nz",
    },
    teamB: {
      name: "Pakistan",
      flag: "pk",
    },
    status: "expired",
    pool: 22,
  },
  {
    id: 24,
    tournament: tournament,
    matchNumber: 36,
    matchTimeStamp: 1699086600000, // Nov 04 2023 02:00 PM
    matchVenue: "Ahmedabad",
    // England vs Australia
    teamA: {
      name: "England",
      flag: "gb-eng",
    },
    teamB: {
      name: "Australia",
      flag: "au",
    },
    status: "expired",
    pool: 23,
  },
  {
    id: 25,
    tournament: tournament,
    matchNumber: 37,
    matchTimeStamp: 1699173000000, // Nov 05 2023 02:00 PM
    matchVenue: "Kolkata",
    // India vs South Africa
    teamA: {
      name: "India",
      flag: "in",
    },
    teamB: {
      name: "South Africa",
      flag: "za",
    },
    status: "expired",
    pool: 24,
  },
  {
    id: 26,
    tournament: tournament,
    matchNumber: 38,
    matchTimeStamp: 1699259400000, // Nov 06 2023 02:00 PM
    matchVenue: "Delhi",
    // Bangladesh vs Sri Lanka
    teamA: {
      name: "Bangladesh",
      flag: "bd",
    },
    teamB: {
      name: "Sri Lanka",
      flag: "lk",
    },
    status: "expired",
    pool: 25,
  },
  {
    id: 27,
    tournament: tournament,
    matchNumber: 39,
    matchTimeStamp: 1699345800000, // Nov 07 2023 02:00 PM
    matchVenue: "Mumbai",
    // Australia vs Afghanistan
    teamA: {
      name: "Australia",
      flag: "au",
    },
    teamB: {
      name: "Afghanistan",
      flag: "af",
    },
    status: "expired",
    pool: 26,
  },
  {
    id: 28,
    tournament: tournament,
    matchNumber: 40,
    matchTimeStamp: 1699432200000, // Nov 08 2023 02:00 PM
    matchVenue: "Pune",
    // England vs Netherlands
    teamA: {
      name: "England",
      flag: "gb-eng",
    },
    teamB: {
      name: "Netherlands",
      flag: "nl",
    },
    status: "expired",
    pool: 27,
  },
  {
    id: 29,
    tournament: tournament,
    matchNumber: 41,
    matchTimeStamp: 1699518600000, // Nov 09 2023 02:00 PM
    matchVenue: "Bengaluru",
    // New Zealand vs Sri Lanka
    teamA: {
      name: "New Zealand",
      flag: "nz",
    },
    teamB: {
      name: "Sri Lanka",
      flag: "lk",
    },
    status: "expired",
    pool: 28,
  },
  {
    id: 30,
    tournament: tournament,
    matchNumber: 42,
    matchTimeStamp: 1699605000000, // Nov 10 2023 02:00 PM
    matchVenue: "Ahmedabad",
    // South Africa vs Afghanistan
    teamA: {
      name: "South Africa",
      flag: "za",
    },
    teamB: {
      name: "Afghanistan",
      flag: "af",
    },
    status: "expired",
    pool: 29,
  },
  {
    id: 31,
    tournament: tournament,
    matchNumber: 43,
    matchTimeStamp: 1699678800000, // Nov 11 2023 10.30 AM
    matchVenue: "Pune",
    // Australia vs Bangladesh
    teamA: {
      name: "Australia",
      flag: "au",
    },
    teamB: {
      name: "Bangladesh",
      flag: "bd",
    },
    status: "expired",
    pool: 30,
  },
  {
    id: 32,
    tournament: tournament,
    matchNumber: 44,
    matchTimeStamp: 1699691400000, // Nov 11 2023 02:00 PM
    matchVenue: "Kolkata",
    // England vs Pakistan
    teamA: {
      name: "England",
      flag: "gb-eng",
    },
    teamB: {
      name: "Pakistan",
      flag: "pk",
    },
    status: "expired",
    pool: 31,
  },
  {
    id: 33,
    tournament: tournament,
    matchNumber: 45,
    matchTimeStamp: 1699777800000, // Nov 12 2023 02:00 PM
    matchVenue: "Bengaluru",
    // India vs Netherlands
    teamA: {
      name: "India",
      flag: "in",
    },
    teamB: {
      name: "Netherlands",
      flag: "nl",
    },
    status: "expired",
    pool: 32,
  },
  {
    id: 34,
    tournament: tournament,
    matchNumber: "1st v 4th",
    matchTimeStamp: 1700037000000, // Nov 15 2023 02:00 PM
    matchVenue: "Mumbai",
    // India vs New Zealand
    teamA: {
      name: "India",
      flag: "in",
    },
    teamB: {
      name: "New Zealand",
      flag: "nz",
    },
    status: "expired",
    pool: 33,
  },
  {
    id: 35,
    tournament: tournament,
    matchNumber: "2nd v 3rd",
    matchTimeStamp: 1700123400000, // Nov 16 2023 02:00 PM
    matchVenue: "Kolkata",
    // South Africa vs Australia
    teamA: {
      name: "South Africa",
      flag: "za",
    },
    teamB: {
      name: "Australia",
      flag: "au",
    },
    status: "expired",
    pool: 34,
  },
  // {
  //   id: 36,
  //   tournament: tournament,
  //   matchNumber: "Final",
  //   matchVenue: "Ahmedabad",
  //   //TBC vs TBC
  //   teamA: {
  //     name: "TBC",
  //     flag: "tbc",
  //   },
  //   teamB: {
  //     name: "TBC",
  //     flag: "tbc",
  //   },
  //   status: "later",
  //   pool: 35,
  // }
];

export const getFlag = (countryCode: string) => `https://flagcdn.com/${countryCode}.svg`;

export const gameData = [
  { image: game1, name: "Chaos", rating: 4.4, plays: "38,847", winnings: "$1,420", link: "https://skillgaming.com/game/export/chaos" },
  { image: game2, name: "Timberkek", rating: 4.6, plays: "155,719", winnings: "$4,809.77", link: "https://skillgaming.com/game/export/timberkek" },
  {
    image: game3,
    name: "NYC Rickshow",
    rating: 4.8,
    plays: "123,812",
    winnings: "$183.68",
    link: "https://skillgaming.com/game/export/nyc-rickshow",
  },
  {
    image: game4,
    name: "Cryptoblast 3",
    rating: 4.5,
    plays: "201,613",
    winnings: "$2,163.00",
    link: "https://skillgaming.com/game/export/cryptoblast-3",
  },
  {
    image: game5,
    name: "Bijou’s Knives",
    rating: 4.1,
    plays: "72,788",
    winnings: "$14,582.54",
    link: "https://skillgaming.com/game/export/bijou-knives",
  },
];

export const LOCAL_STORAGE_EXCEPTION = [TourGuide.logout, TourGuide.login, TourGuide.enteredDraw, TourGuide.match];

export const TOUR_CONFIG: { [tourName: string]: Array<any> } = {
  // [TourGuide.logout]: [
  //   {
  //     selector: '[data-tut="tour-wallet-connect"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Connect your MetaMask wallet by clicking on this button.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-wallet-connect-1"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Yeah! you have one more option to connect your MetaMask wallet.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-buy-ticket-input"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Purchase the desired number of tickets using this input.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-buy-ticket-rounds"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Utilize the most effective round to secure a greater advantage.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-ticket"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Here is where your tickets will be displayed.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-total-tickets"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can locate the total number of distributed tickets here.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-recent-winners"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>The winners of the most recent rounds will be showcased here.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-games-list"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Select a game you enjoy playing in order to increase your earnings.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-game-info"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can locate information about the selected game here.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-game-play"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Here, indulge in your favorite game.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-game-predict"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Here, you can make predictions about the moments in your favorite sport's matches.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-game-predict-button"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>
  //           Clicking this button will redirect you to a match prediction page where you can find a wide range of existing matches to predict and earn
  //           rewards.
  //         </S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  // ],
  // [TourGuide.login]: [
  //   {
  //     selector: '[data-tut="tour-wallet-balance"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can view the balance of your currently connected wallet here.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-wallet-connect"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can find the currently connected wallet address here.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-ticket-deposit"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can purchase tickets for the specified amount by clicking this button.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-wallet-logout"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can find an option to disconnect.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  // ],
  // [TourGuide.enteredDraw]: [
  //   {
  //     selector: '[data-tut="tour-your-ticket"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>This is your lucky pass. Here, you can find you lucky pass numbers. </S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-my-progress"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can monitor your progress in the lucky round here.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-yearn-stake"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Here,You can stake your YEARN for the specified amount.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-yearn-unstake"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Here,You can unstake your YEARN for the specified amount.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },

  //   {
  //     selector: '[data-tut="tour-my-rewards"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Here,You can locate your rewards.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-my-stakes"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Here,You can locate your stakes.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-earned-yearn"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Here,You can locate your earned YEARN.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-yearn-reward-claim"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can claim your earned Yearn rewards by clicking this button.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-my-earnings"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>
  //           You can find your total earnings here. By clicking the downward arrow, you can access the option to claim your earnings.
  //         </S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-my-tokens"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>
  //           You can find the total YEARN tokens you've earned here. By clicking the downward arrow, you can access the option to claim your YEARN
  //           tokens.
  //         </S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-user-info"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>Here,You can find your basic information with this application.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-user-refer"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>
  //           You can find your unique refferal url here, By clicking this refer button, you can access the option to copy the unique refferal url.
  //         </S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  // ],
  // [TourGuide.match]: [
  //   {
  //     selector: '[data-tut="tour-match-info"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can access match information here, including details such as the date, match number, and venue.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-match-progress"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can observe the progress of each team here.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-match-locks"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You can locate the timer/status locks here, which indicate when the current match prediction will be locked.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-match-predict-option"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>You have the option to choose the team you want to predict.</S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  //   {
  //     selector: '[data-tut="tour-match-predict-button"]',
  //     content: (
  //       <S.TourTextContainer>
  //         <S.TourSubText>
  //           You can enter the prediction amount using the input field provided, and once you've entered the amount, you can utilize the "Predict"
  //           button to make the prediction.
  //         </S.TourSubText>
  //       </S.TourTextContainer>
  //     ),
  //     style: {
  //       backgroundColor: "#16151A",
  //       color: "white",
  //     },
  //   },
  // ],
  [TourGuide.none]: [],
  [TourGuide.logout]: [],
  [TourGuide.login]: [],
  [TourGuide.enteredDraw]: [],
  [TourGuide.match]: [],
};

// Rounds
export const TICKETS_PER_ROUND = {
  [Round.roundOne]: 1500,
  [Round.roundTwo]: 3000,
  [Round.roundThree]: 5000,
};

export const AMOUNT_PER_ROUND = {
  [Round.roundOne]: 0.009,
  [Round.roundTwo]: 0.01,
  [Round.roundThree]: 0.0125,
};
