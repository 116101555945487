import { Box, IconButton, Typography, styled } from "@mui/material";
import GameBackground from "../../../assets/images/gameBackground.png";
import NoTournamentBg from "../../../assets/images/no-tournament-image.svg";
import SoccerBackground from "../../../assets/images/soccerBackground.png";
import { TextGradient, TransparentTextGradient } from "../../../utils/constants";

namespace S {
  export const GameContainer = styled(Box)(({ theme }) => ({
    padding: "1.25rem 0rem 1.25rem 0rem",
    boxSizing: "border-box",
    width: "380px",
    height: "100%",
    maxHeight: "512px",
    // maxHeight: "30.4rem",
    borderRadius: "0.625rem",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    display: "flex",
    flexDirection: "column",
    // gap: "1.40625rem",
    gap: "1.50625rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const Header = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "0rem 1.25rem 0rem 1.25rem",
  }));

  export const HeadContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
  }));

  export const TabContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "1rem",
    justifyContent: "space-around",
  }));

  export const ImageContainer = styled(Box)(({ theme }) => ({
    backgroundImage: `URL(${GameBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }));

  export const NoTournamentContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "276px",
    backgroundImage: `URL(${NoTournamentBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    margin: "13px 0",
  }));

  export const NoTournamentText = styled(Typography)(({ theme }) => ({
    fontSize: "40px",
    fontWeight: 700,
    fontStyle: "italic",
    textAlign: "center",
    lineHeight: "50px",
    textShadow: "0px 4px 10px 0px #000000CC",
  }));

  export const SorryText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 700,
    fontStyle: "italic",
    textAlign: "left",
    width: "83%",
    lineHeight: 1,
    color: theme.palette.custom.accent.accent_2,
  }));

  export const InnerImageContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: "30px",
  }));

  export const GameImage = styled("img")(({ theme }) => ({
    width: "auto",
    height: "4rem",
  }));

  export const soccerImageContainer = styled(Box)(({ theme }) => ({
    backgroundImage: `URL(${SoccerBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "276px",
  }));

  export const soccerButtonContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    justifyContent: "center",
    padding: "1.25rem 1.25rem 0rem 1.25rem",
    alignItems: "center",
    // display: "flex",
    width: "100%",
    flex: 1,
  }));

  export const TotalImageContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    // height: "200px",
    boxSizing: "border-box",
    paddingTop: "10px",
    gap: "10px",
  }));

  export const PlayerImageContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
  }));

  export const PlayerImage = styled("img")(({ theme }) => ({
    width: "82px",
  }));

  export const CountryLogoImage = styled("img")(({ theme }) => ({
    width: "50px",
    height: "50px",
    // width: "100%",
    // height: "100%",
  }));

  export const BarContainer = styled(Box)(({ theme }) => ({
    marginTop: "-20px",
  }));

  export const BarImage = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
  }));

  export const TextContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }));

  export const CommingSoonText = styled(Box)(({ theme }) => ({
    fontSize: "40px",
    fontStyle: "italic",
    fontWeight: 700,
    lineHeight: "60px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
    },
  }));

  export const VersesImage = styled("img")(({ theme }) => ({
    width: "87px",
  }));

  export const GradientText = styled(Typography, { shouldForwardProp: (prop) => prop !== "shineText" })<{ shineText: string }>(
    ({ theme, shineText }) => ({
      background: TextGradient,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      backgroundSize: "200px",
      // fontWeight: 500,
      // fontSize: "14px",
      // lineHeight: "24.15px",
      fontSize: "1rem !important",
      fontWeight: "bold !important",
      color: TextGradient,
      textShadow: `0 0px 0px ${TextGradient}`,
      position: "relative",
      ":after": {
        content: `"${shineText}"`,
        // minWidth: "200px",
        background: TransparentTextGradient,
        webkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translate(0,0)",
        zIndex: 3,
        animation: "pool-shine 20s linear infinite",
        "@keyframes pool-shine": {
          "0%": {
            backgroundPosition: "-300px",
          },
          "100%": {
            backgroundPosition: "300px",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important",
      },
    }),
  );

  export const ListIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== "disabled",
  })(({ disabled }: { disabled: boolean }) => ({ theme }) => ({
    background: disabled ? theme.palette.custom.secondary.secondary_2 : theme.palette.custom.primary.main,
    height: "1.5rem",
    width: "1.5rem",
    pointerEvents: disabled ? "none" : "auto",
    svg: {
      fontSize: "1rem",
    },
    "&:hover": {
      backgroundColor: disabled ? "" : "#1846dd",
    },
  }));

  export const ToggleText = styled(Typography, { shouldForwardProp: (prop) => prop !== "active" })(
    ({ active }: { active: boolean }) =>
      ({ theme }) => ({
        fontWeight: 600,
        // fontSize: "1.25rem",
        fontSize: "20px",
        lineHeight: "30px",
        color: active ? "#fff" : theme.palette.custom.secondary.secondary_2,
        position: "relative",
        cursor: "pointer",
        borderBottom: active ? `2px solid ${theme.palette.custom.primary.main}` : "",
        height: "32px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "1rem",
        },
      }),
  );

  export const Body = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "0rem 1.25rem 0rem 1.25rem",
  }));

  export const TrophyContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "2rem",
  }));

  export const TrophyTextContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  }));

  export const LocksInContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
  }));

  export const TournamentText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "27px",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  export const TrophyText = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  }));

  export const TrophySubText = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.palette.custom.secondary.secondary_2,
  }));

  export const LocksInText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "27px",
    color: theme.palette.custom.accent.accent_2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));
}

export default S;
