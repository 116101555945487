import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { Box, Tooltip, Typography } from "@mui/material";
import { useAccount } from "wagmi";
import { SInvitedByPopover, SReferPopover } from "./popovers.styled";
import ProfileImage from "../../assets/images/ProfileImage.png";
import User3 from "../../assets/images/User3.png";
import USDTLogo from "../../assets/images/usdtLogo.svg";
import { onrampInstance } from "../..";
import { withdrawPredictTokens, withdrawTokens } from "../../store/slices/wallet.actions";
import { REFERRAL_PATH_NAME } from "../../utils/constants";
import { getEllipsisString, getRoundAmount, getWholeNum, toFixed, useGetDimensions } from "../../utils/helpers";
import {
  useClaimableToken,
  useDrawAmount,
  useEnteredDraw,
  useInvitedBy,
  useIsAppLoading,
  usePredictGameToken,
  useUserDetails,
  useUserTickets,
  useWinnerOverlay,
} from "../../utils/hooks/selector";
import { useAppDispatch } from "../../utils/hooks/state";
import { CustomPopoverProps } from "../../utils/types";
import { Triangle } from "../../utils/widgets";
import CustomPopover from "../../utils/widgets/popover";
import InputWithCopyAndShare from "../input-with-copy-and-share";
import MantraModal from "../modals/mantras";
import { EarnedTokensPopoverContentAccordion, MyEarningsPopoverAccordion } from "./popoverContent";

const InvitedByPopover: FC<CustomPopoverProps> = (props) => {
  //constructors
  const dispatch = useAppDispatch();

  //constants
  const { id, isOpen, onClose } = props;
  const isSm = useGetDimensions();
  const isMd = useGetDimensions("md");
  const popoverCustomStyle = {
    top: isSm ? 12 : isMd ? 13 : 16,
    left: isMd ? 10 : -80,
    ".css-jx6v87-MuiPaper-root-MuiPopover-paper": {
      maxWidth: isMd ? "calc(100% - 2px)" : "calc(100% - 32px)",
      maxHeight: isMd ? "calc(100vh - 20vh)" : "calc(100% - 32px)",
    },
  };

  //state values
  const invitedBy = useSelector(useInvitedBy);
  // const enteredDraw = useSelector(useEnteredDraw);
  const enteredDraw = true;
  const userDetails = useSelector(useUserDetails);
  const ticketNumbers = useSelector(useUserTickets).ticketNumbers;
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isAppLoading = useSelector(useIsAppLoading);
  const drawAmount = useSelector(useDrawAmount);
  const claimableToken = useSelector(useClaimableToken);
  const predictGameToken = useSelector(usePredictGameToken);

  const [toolTipTime, setToolTipTime] = useState(false);
  const [profitMantra, setProfitMantra] = useState(false);
  const { isConnected: isWalletConnected } = useAccount();

  //functions
  const handleAddressCopy = (link: string) => {
    setToolTipTime(true);
    navigator.clipboard.writeText(link);
    setTimeout(() => {
      setToolTipTime(false);
    }, 3000);
  };

  const handleClaim = () => {
    dispatch(withdrawTokens());
  };

  const handlePredictClaim = () => {
    dispatch(withdrawPredictTokens());
  };

  const totalTokens = getRoundAmount(
    `${BigInt(claimableToken.claimed) + BigInt(claimableToken.unclaimed) + BigInt(predictGameToken.claimed) + BigInt(predictGameToken.unclaimed)}`,
  );

  //On WinnerTimerModal or isAppLoading Open
  useEffect(() => {
    if (winnerOverlay || isAppLoading) {
      setProfitMantra(false);
    }
  }, [winnerOverlay, isAppLoading]);

  return (
    <>
      {Boolean(isOpen) && <Triangle customStyle={{ top: isMd ? 30 : 40, left: isMd ? 6 : 85, borderBottomColor: isMd ? "#2d2c31" : "" }} />}
      <CustomPopover id={id} isOpen={isOpen} onClose={onClose} customStyle={popoverCustomStyle}>
        <SInvitedByPopover.Wrapper>
          <SInvitedByPopover.Header customStyle={{ display: !enteredDraw ? "none" : "flex" }}>
            <SInvitedByPopover.Profile>
              <img src={ProfileImage} alt="user_dp" />
              <Box>
                <Typography variant={isSm ? "overline" : "caption"}>USER ID</Typography>
                <Typography>#{userDetails.id}</Typography>
              </Box>
            </SInvitedByPopover.Profile>
            <SInvitedByPopover.Ticket>
              {!userDetails.isFreeUser && !isSm && (
                <Box>
                  <Typography variant={isSm ? "overline" : "caption"}>LUCKY PASS</Typography>
                  <Typography>
                    {ticketNumbers.length > 1
                      ? `YT ${getWholeNum(ticketNumbers[0])} - YT ${getWholeNum(ticketNumbers[ticketNumbers.length - 1])}`
                      : `YT ${getWholeNum(ticketNumbers[0])}`}
                  </Typography>
                </Box>
              )}
              <Box>
                <Typography variant={isSm ? "overline" : "caption"}>
                  PRICE
                  <SInvitedByPopover.SubText>(BEP20)</SInvitedByPopover.SubText>
                </Typography>
                <SInvitedByPopover.TicketAmount>{drawAmount} USDT</SInvitedByPopover.TicketAmount>
              </Box>
              {/* <Tooltip title={toolTipTime ? "copied to clipboard" : "copy to clipboard"} arrow>
                <SInvitedByPopover.CopyIcon onClick={() => handleAddressCopy(userDetails.address)}>
                  <ContentCopyTwoToneIcon />
                </SInvitedByPopover.CopyIcon>
              </Tooltip> */}
            </SInvitedByPopover.Ticket>
          </SInvitedByPopover.Header>
          <SInvitedByPopover.Body customStyle={{ paddingTop: !enteredDraw ? "1.25rem" : 0 }}>
            {isSm && (
              <Box display="flex" justifyContent="center" flexDirection="column" gap={2}>
                <SInvitedByPopover.Highlight variant="subtitle2">LUCKY PASS</SInvitedByPopover.Highlight>
                <SInvitedByPopover.UserAddress>
                  {ticketNumbers.length > 1
                    ? `YT ${getWholeNum(ticketNumbers[0])} - YT ${getWholeNum(ticketNumbers[ticketNumbers.length - 1])}`
                    : `YT ${getWholeNum(ticketNumbers[0])}`}
                </SInvitedByPopover.UserAddress>
              </Box>
            )}

            {/* {isWalletConnected && isSm && ( */}
            {isWalletConnected && enteredDraw && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" justifyContent="center" flexDirection="column" gap={2}>
                  <SInvitedByPopover.Highlight variant="subtitle2">MY BALANCE</SInvitedByPopover.Highlight>
                  <SInvitedByPopover.UserAddress>{toFixed(Number(userDetails.balance), 4)} USDT</SInvitedByPopover.UserAddress>
                </Box>
                <Box display="flex" justifyContent="center" flexDirection="column" gap={2}>
                  <SInvitedByPopover.Highlight variant="subtitle2">EARNED TOKENS</SInvitedByPopover.Highlight>
                  <SInvitedByPopover.UserAddress>
                    {toFixed(Number(totalTokens), 4)} {process.env.REACT_APP_YTG_TOKEN_SYMBOL}
                  </SInvitedByPopover.UserAddress>
                </Box>
                {/* {isSm && (
                  <SInvitedByPopover.BuyButton
                    onClick={() => {
                      onrampInstance.show();
                    }}
                  >
                    Buy
                    <img className="img" src={USDTLogo} alt="usdt" />
                    USDT
                  </SInvitedByPopover.BuyButton>
                )} */}
              </Box>
            )}

            {enteredDraw && (
              <Box display="flex" justifyContent="center" flexDirection="column" gap={2}>
                <SInvitedByPopover.Highlight variant="subtitle2">MY ADDRESS</SInvitedByPopover.Highlight>
                <SInvitedByPopover.AddressAlignmentBox>
                  <SInvitedByPopover.UserAddress>
                    {isSm ? getEllipsisString(userDetails.address, 15, 12) : userDetails.address}
                  </SInvitedByPopover.UserAddress>
                  <Tooltip title={toolTipTime ? "copied to clipboard" : "copy to clipboard"} arrow>
                    <SInvitedByPopover.CopyIcon onClick={() => handleAddressCopy(userDetails.address)}>
                      <ContentCopyTwoToneIcon />
                    </SInvitedByPopover.CopyIcon>
                  </Tooltip>
                </SInvitedByPopover.AddressAlignmentBox>
              </Box>
            )}
            {invitedBy && (
              <Box display="flex" justifyContent="center" flexDirection="column" gap={2}>
                <SInvitedByPopover.Highlight variant="subtitle2">INVITED BY</SInvitedByPopover.Highlight>
                <Box display="flex" alignItems="center" gap={3}>
                  <img src={User3} alt="invited_user_dp" height={40} width={40} />
                  <Typography>#{invitedBy.referrerId}</Typography>
                </Box>
                <SInvitedByPopover.AddressAlignmentBox>
                  <SInvitedByPopover.UserAddress>{invitedBy.address}</SInvitedByPopover.UserAddress>
                  <Tooltip title={toolTipTime ? "copied to clipboard" : "copy to clipboard"} arrow>
                    <SInvitedByPopover.CopyIcon onClick={() => handleAddressCopy(invitedBy.address)}>
                      <ContentCopyTwoToneIcon />
                    </SInvitedByPopover.CopyIcon>
                  </Tooltip>
                </SInvitedByPopover.AddressAlignmentBox>
              </Box>
            )}
            {enteredDraw && (
              <SReferPopover.InvitedByWrapper>
                <SReferPopover.Highlight variant="overline">Share this unique URL with your friends:</SReferPopover.Highlight>
                <InputWithCopyAndShare
                  Url={`${window.location.origin}/${REFERRAL_PATH_NAME}/${userDetails.id}/${userDetails.address}`}
                  InputContainerStyle={{ maxWidth: "28rem" }}
                />
              </SReferPopover.InvitedByWrapper>
            )}
            {isMd && (
              <>
                <MyEarningsPopoverAccordion />
                <EarnedTokensPopoverContentAccordion handleClaim={handleClaim} handlePredictClaim={handlePredictClaim} />
              </>
            )}
            <SInvitedByPopover.Footer>
              <SInvitedByPopover.FooterText variant="caption">
                <a href={`${process.env.REACT_APP_DOCS_URL}/yearntogether/frequently-asked-questions`} target="_blank">
                  FAQ’s
                </a>
              </SInvitedByPopover.FooterText>
              <SInvitedByPopover.FooterText variant="caption">
                <a href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>Help?</a>
              </SInvitedByPopover.FooterText>
              {/* <SInvitedByPopover.FooterText variant="caption" onClick={() => setProfitMantra(true)}>
                Profit Mantra
              </SInvitedByPopover.FooterText> */}
            </SInvitedByPopover.Footer>
          </SInvitedByPopover.Body>
        </SInvitedByPopover.Wrapper>
      </CustomPopover>
      {profitMantra && <MantraModal open={true} handleClose={() => setProfitMantra(false)} />}
    </>
  );
};

export default InvitedByPopover;
