import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, InputAdornment, IconButton, Tooltip } from "@mui/material";
import { useAccount, useWalletClient } from "wagmi";
import { SIcon, SInvitedByPopover, SMyEarningsPopover } from "./popovers.styled";
import { SNewEarnedTokensPopover, YellowText } from "./popovers.styled";
import MetamaskIcon from "../../assets/images/metamask-icon.svg";
import OpenInExplorerIcon from "../../assets/images/open-in-explorer.svg";
import YEARNLOGO from "../../assets/images/yearn-coin.svg";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { addYTGToWallet } from "../../services/helpers";
import { CURRENT_CHAIN_ID, GRADIENT, YEARN_TOKEN } from "../../utils/constants";
import { convertWeiToEth, exploreAccount, getEllipsisString, getRoundAmount } from "../../utils/helpers";
import {
  useAddingYTG,
  useClaimableAmount,
  useClaimableToken,
  useIsReady,
  useIsWithdrawingPredictTokens,
  useIsWithdrawingTokens,
  useMyEarnings,
  useNextAvailableAt,
  usePredictGameToken,
  useUserDetails,
} from "../../utils/hooks/selector";
import { yearnTokenAddress } from "../../utils/providers/platforms";
import { Button, GradientButton } from "../../utils/widgets";
import Toast from "../../utils/widgets/toast";

interface EarnedTokenProps {
  handleClaim: () => void;
  handlePredictClaim: () => void;
}

export const MyEarningsPopoverAccordion = () => {
  //constructor
  const { address } = useAccount();

  //state values
  const userDetails = useSelector(useUserDetails);
  const myEarnings = useSelector(useMyEarnings);
  const claimableAmount = useSelector(useClaimableAmount);
  const isReady = useSelector(useIsReady);
  const [isClaimable, setIsClaimable] = useState<boolean>(false);
  const [claimAmount, setClaimAmount] = useState("");
  const [maxSelected, setMaxSelected] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [openMyEarnings, setOpenMyEarnings] = useState(false);

  //constants
  const totalEarnings = myEarnings.winning + myEarnings.winnerReferrerBonuses;

  //functions
  const onAmountChange = (e: any) => {
    if (maxSelected) setMaxSelected(false);
    setClaimAmount(e.target.value);
  };

  const onClickMax = () => {
    setMaxSelected(true);
    setClaimAmount(claimableAmount.unclaimed.toString());
  };

  const onClaimAmount = () => {
    if (!isReady) return Toast({ message: "Contracts are not ready yet, try after some time!", type: "warning" });

    if (!isClaiming) {
      if (claimAmount === "" || claimAmount === "0") {
        Toast({ message: "Enter a valid amount!" });
        return;
      }
      setIsClaiming(true);
      if (maxSelected) {
        lottoContract.claimAmount(claimableAmount.unclaimed.toString(), `${address}`, () => {
          setIsClaiming(false);
          setClaimAmount("");
        });
      } else {
        lottoContract.claimAmount(claimAmount, `${address}`, () => {
          setIsClaiming(false);
          setClaimAmount("");
        });
      }
    }
  };

  useEffect(() => {
    if (userDetails.address && isReady) lottoContract.getIsClaimActive().then((res: boolean) => setIsClaimable(res));
  }, [isReady, userDetails.address]);

  return (
    <Box sx={{ maxWidth: "calc(100% - 2px)", boxSizing: "border-box" }}>
      <SMyEarningsPopover.AccordionHeader onClick={() => setOpenMyEarnings(!openMyEarnings)}>
        <SMyEarningsPopover.AccordionHeaderText>My Earnings</SMyEarningsPopover.AccordionHeaderText>
        <IconButton>{openMyEarnings ? <SIcon.CustomKeyboardArrowDownIcon /> : <SIcon.CustomKeyboardArrowRightIcon />}</IconButton>
      </SMyEarningsPopover.AccordionHeader>
      <SMyEarningsPopover.Body sx={{ display: openMyEarnings ? "flex" : "none", padding: "20px 0 0" }}>
        <Box>
          <SMyEarningsPopover.Text1 variant="subtitle2">My Address</SMyEarningsPopover.Text1>
          <SInvitedByPopover.UserAddress variant="body1">{getEllipsisString(userDetails.address, 14, 13)}</SInvitedByPopover.UserAddress>
        </Box>
        <SMyEarningsPopover.Row>
          <SMyEarningsPopover.Text1 variant="subtitle2">Earned from Winnings</SMyEarningsPopover.Text1>
          <SMyEarningsPopover.Text3 variant="subtitle1">${myEarnings.winning}</SMyEarningsPopover.Text3>
        </SMyEarningsPopover.Row>
        <SMyEarningsPopover.Row>
          <SMyEarningsPopover.Text1 variant="subtitle2">Earned from Referrals</SMyEarningsPopover.Text1>
          <SMyEarningsPopover.Text3 variant="subtitle1">${myEarnings.winnerReferrerBonuses}</SMyEarningsPopover.Text3>
        </SMyEarningsPopover.Row>
        <SMyEarningsPopover.Row>
          <SMyEarningsPopover.Text1 variant="subtitle2">Unclaimed Amount</SMyEarningsPopover.Text1>
          <SMyEarningsPopover.Text3 variant="subtitle1">${claimableAmount.unclaimed}</SMyEarningsPopover.Text3>
        </SMyEarningsPopover.Row>
        <SMyEarningsPopover.Row>
          <SMyEarningsPopover.Text1 variant="subtitle2">Claimed Amount</SMyEarningsPopover.Text1>
          <SMyEarningsPopover.Text3 variant="subtitle1">${claimableAmount.claimed}</SMyEarningsPopover.Text3>
        </SMyEarningsPopover.Row>
        <SMyEarningsPopover.AmountInputField
          placeholder="Enter claim amount"
          onChange={onAmountChange}
          value={claimAmount}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SMyEarningsPopover.MaxChipWrapper onClick={onClickMax} disabled={maxSelected || claimableAmount.unclaimed === 0}>
                  <SMyEarningsPopover.MaxText disabled={maxSelected || claimableAmount.unclaimed === 0}>MAX</SMyEarningsPopover.MaxText>
                </SMyEarningsPopover.MaxChipWrapper>
              </InputAdornment>
            ),
          }}
          disabled={claimableAmount.unclaimed === 0 || !isClaimable}
        />
        <SMyEarningsPopover.MaxAmountWarningText isVisible={Number(claimAmount) !== 0 && Number(claimAmount) === claimableAmount.unclaimed}>
          You have reached the maximum value
        </SMyEarningsPopover.MaxAmountWarningText>
        <SMyEarningsPopover.AmountExceededWarningText isVisible={Number(claimAmount) > claimableAmount.unclaimed}>
          You have exceeded the maximum value
        </SMyEarningsPopover.AmountExceededWarningText>
        <SMyEarningsPopover.ClaimAmountWrapper>
          <GradientButton
            loading={isClaiming}
            children={isClaiming ? "Claiming..." : "Claim Amount"}
            buttonType={GRADIENT}
            onClick={onClaimAmount}
            disabled={claimableAmount.unclaimed === 0 || !isClaimable}
          />
        </SMyEarningsPopover.ClaimAmountWrapper>
        <SMyEarningsPopover.Footer>
          <SMyEarningsPopover.Row>
            <SMyEarningsPopover.Text2>TOTAL EARNED</SMyEarningsPopover.Text2>
            <SMyEarningsPopover.Text4>${totalEarnings}</SMyEarningsPopover.Text4>
          </SMyEarningsPopover.Row>
        </SMyEarningsPopover.Footer>
      </SMyEarningsPopover.Body>
    </Box>
  );
};

export const EarnedTokensPopoverContentAccordion: FC<EarnedTokenProps> = (props) => {
  //props
  const { handleClaim, handlePredictClaim } = props;

  //state values
  const isWithdrawingTokens = useSelector(useIsWithdrawingTokens);
  const claimableToken = useSelector(useClaimableToken);
  const predictGameToken = useSelector(usePredictGameToken);
  const isWithdrawingPredictTokens = useSelector(useIsWithdrawingPredictTokens);
  const isReady = useSelector(useIsReady);
  const userDetails = useSelector(useUserDetails);
  const nextAvailableAt = useSelector(useNextAvailableAt);
  const addingYTG = useSelector(useAddingYTG);
  const { data: signer } = useWalletClient();
  const [claim, setClaim] = useState(false);
  const [openEarnedTokens, setOpenEarnedTokens] = useState(false);
  const [toolTipTime, setToolTipTime] = useState(false);
  const explorerUrl = exploreAccount(CURRENT_CHAIN_ID, yearnTokenAddress[CURRENT_CHAIN_ID]);
  const currentTokenAddress = yearnTokenAddress[CURRENT_CHAIN_ID];
  //constants
  const totalTokens = getRoundAmount(
    `${BigInt(claimableToken.claimed) + BigInt(claimableToken.unclaimed) + BigInt(predictGameToken.claimed) + BigInt(predictGameToken.unclaimed)}`,
  );

  //To enable claim
  useEffect(() => {
    if (isReady && userDetails.address) {
      lottoContract.getIsWithdrawalEnabled().then((res: boolean) => setClaim(res));
    }
  }, [isReady, userDetails.address]);

  const handleAddressCopy = () => {
    setToolTipTime(true);
    navigator.clipboard.writeText(currentTokenAddress);
    setTimeout(() => {
      setToolTipTime(false);
    }, 3000);
  };

  const handleOpenExplorerLink = () => {
    window.open(explorerUrl);
  };

  return (
    <Box sx={{ maxWidth: "calc(100% - 2px)", boxSizing: "border-box" }}>
      <SMyEarningsPopover.AccordionHeader onClick={() => setOpenEarnedTokens(!openEarnedTokens)}>
        <SMyEarningsPopover.AccordionHeaderText>Earned Tokens</SMyEarningsPopover.AccordionHeaderText>
        <IconButton>{openEarnedTokens ? <SIcon.CustomKeyboardArrowDownIcon /> : <SIcon.CustomKeyboardArrowRightIcon />}</IconButton>
      </SMyEarningsPopover.AccordionHeader>

      <SNewEarnedTokensPopover.Body sx={{ display: openEarnedTokens ? "flex" : "none", padding: "0" }}>
        <SNewEarnedTokensPopover.CopyContainer>
          <SNewEarnedTokensPopover.CustomInput
            id="input-with-icon-adornment"
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#ffffff",
              },
            }}
            endAdornment={
              <SNewEarnedTokensPopover.CustomInputAdornment position="start">
                <Tooltip title={toolTipTime ? "copied to clipboard" : "Click to copy"} arrow>
                  <SNewEarnedTokensPopover.IconContainer onClick={() => handleAddressCopy()}>
                    <SNewEarnedTokensPopover.CustomCopyIcon />
                  </SNewEarnedTokensPopover.IconContainer>
                </Tooltip>
                <Tooltip title="Add to MetaMask" arrow>
                  <SNewEarnedTokensPopover.IconContainer onClick={() => addYTGToWallet(signer)}>
                    <SNewEarnedTokensPopover.MetamaskIcon src={MetamaskIcon} alt="" />
                  </SNewEarnedTokensPopover.IconContainer>
                </Tooltip>
                <Tooltip title="Open in explorer" arrow>
                  <SNewEarnedTokensPopover.IconContainer onClick={() => handleOpenExplorerLink()}>
                    <SNewEarnedTokensPopover.OpenExplorerImg src={OpenInExplorerIcon} alt="" />
                  </SNewEarnedTokensPopover.IconContainer>
                </Tooltip>
              </SNewEarnedTokensPopover.CustomInputAdornment>
            }
            disableUnderline
            disabled
            value={getEllipsisString(currentTokenAddress, 5, 4)}
          />
        </SNewEarnedTokensPopover.CopyContainer>
        <SNewEarnedTokensPopover.Header>
          <SNewEarnedTokensPopover.HeaderAmountSection>
            <SNewEarnedTokensPopover.HeaderLogo src={YEARNLOGO} alt="yearn-logo" />
            <SNewEarnedTokensPopover.HeaderAmountTextContainer>
              <Typography fontWeight={500} variant="caption">
                Total Tokens
              </Typography>
              <SNewEarnedTokensPopover.HeaderAmount variant="body1">
                {totalTokens} {YEARN_TOKEN}
              </SNewEarnedTokensPopover.HeaderAmount>
              <Typography fontWeight={500} variant="caption">
                1 {YEARN_TOKEN} = 1 wYEARN
              </Typography>
            </SNewEarnedTokensPopover.HeaderAmountTextContainer>
          </SNewEarnedTokensPopover.HeaderAmountSection>
        </SNewEarnedTokensPopover.Header>
        <SNewEarnedTokensPopover.Body sx={{ padding: "0" }}>
          <Box display={"flex"} alignItems={"center"}>
            <SNewEarnedTokensPopover.BodyHeadTitle variant="h6">Lotto Game</SNewEarnedTokensPopover.BodyHeadTitle>
            <SNewEarnedTokensPopover.IBtnContainer>i</SNewEarnedTokensPopover.IBtnContainer>
          </Box>
          <SNewEarnedTokensPopover.BodyDetailsContainer>
            <SNewEarnedTokensPopover.BodyTitle variant="caption" fontWeight={"500"}>
              Claimed Tokens
            </SNewEarnedTokensPopover.BodyTitle>
            <SNewEarnedTokensPopover.BodyTitle variant="subtitle1" fontWeight={"bold"}>
              {convertWeiToEth(claimableToken.claimed)}
            </SNewEarnedTokensPopover.BodyTitle>
          </SNewEarnedTokensPopover.BodyDetailsContainer>
          <SNewEarnedTokensPopover.BodyDetailsContainer>
            <SNewEarnedTokensPopover.BodyDetail variant="caption" fontWeight={"500"}>
              Unclaimed Tokens
            </SNewEarnedTokensPopover.BodyDetail>
            <SNewEarnedTokensPopover.BodyDetail variant="subtitle1" fontWeight={"bold"}>
              {convertWeiToEth(claimableToken.unclaimed)}
            </SNewEarnedTokensPopover.BodyDetail>
          </SNewEarnedTokensPopover.BodyDetailsContainer>
        </SNewEarnedTokensPopover.Body>
        <SNewEarnedTokensPopover.BtnContainer>
          <YellowText>{nextAvailableAt}</YellowText>
          <GradientButton
            buttonType={GRADIENT}
            onClick={handleClaim}
            containerCustomStyle={{ width: "100% !important" }}
            customStyle={{ width: "100% !important", height: "2rem", padding: "0.3125rem 1rem" }}
            disabled={Number(claimableToken.unclaimed) === 0 || isWithdrawingTokens || Boolean(nextAvailableAt.length)}
            loading={isWithdrawingTokens}
          >
            {isWithdrawingTokens ? "Claiming..." : "Claim Tokens"}
          </GradientButton>
        </SNewEarnedTokensPopover.BtnContainer>

        <SNewEarnedTokensPopover.Footer>
          <Button
            buttonType="primary"
            containerCustomStyle={{ width: "100%" }}
            customStyle={{ width: "100% !important", height: "2rem", padding: "0.3125rem 1rem" }}
            onClick={() => addYTGToWallet(signer)}
            disabled={addingYTG}
            loading={addingYTG}
          >
            {addingYTG ? `Adding ${YEARN_TOKEN} to Wallet...` : `+ Add ${YEARN_TOKEN} to Wallet`}
          </Button>
        </SNewEarnedTokensPopover.Footer>
      </SNewEarnedTokensPopover.Body>
    </Box>
  );
};
